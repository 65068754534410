<template>
  <!--直播详情-->
  <div class="container">
    <el-breadcrumb
      separator="/"
      v-show="params.from && params.from != 'unifiedVideo'"
    >
      <el-breadcrumb-item :to="{ path: '' }">
        <i @click="goBack" class="el-icon-back">返回</i>
      </el-breadcrumb-item>
    </el-breadcrumb>
    <div class="wxq_container">
      <div class="wxq_shade" v-if="preview"></div>
      <div class="common_inner">
        <div class="wxq_live">
          <div
            :style="{ width: isChat || fileId ? '896px' : '1200px' }"
            class="wxq_live_left"
          >
            <div id="wxq_live_left"></div>
            <live-video
              :coverUrl="coverUrl"
              :liveInfo="liveInfo"
              :to="video"
              :type="'liveType'"
            />
            <div class="wxq_live_title">
              <p>{{ courseTitle }}</p>
              <div class="wxq_live_operation">
                <div class="eyes" v-if="liveInfo.liveOnlineState == 1">
                  <img
                    src="@/assets/img/studentImg/liveDetail/audience-icon.png"
                    alt=""
                  />
                  <!-- 真实在线人数 -->
                  <span v-if="liveInfo.liveOnlineType == 1">{{
                    liveOnlineBaseNum
                  }}</span>
                  <!-- 真实累计观看人数 -->
                  <span v-if="liveInfo.liveOnlineType == 2">
                    {{ realViewNum }}</span
                  >
                </div>
                <span @click="getOperation">
                  <i
                    :class="[
                      isCollect ? 'el-icon-star-on' : 'el-icon-star-off',
                    ]"
                  ></i>
                  <span v-show="!isCollect">收藏</span>
                  <span v-show="isCollect">取消收藏</span>
                  <img
                    class="thumbs"
                    src="@/assets/img/studentImg/liveDetail/thumbs-icon.png"
                    alt=""
                    @click.stop="good"
                    v-if="liveInfo.liveZanState == 1"
                  />
                  <span
                    v-if="liveInfo.liveZanShow == 1"
                    class="thumbs_absolute"
                    >{{ liveZanBaseNum }}</span
                  >
                </span>
                <!--<span><img src="@/assets/img/studentImg/liveDetail/share-icon.png" alt="">分享</span>-->
                <!-- <span><img src="@/assets/img/studentImg/liveDetail/audience-icon.png" alt="">观众：{{ audience }}</span> -->
              </div>
            </div>
            <div class="yh_canvas" style="pointer-events: none; z-index: 9">
              <canvas id="cvs"></canvas>
            </div>
          </div>
          <div v-if="isChat || fileId" class="wxq_live_right">
            <div v-if="fileId != 0 || fileId != ''" id="wxq_live_right">
              <span class="wxq_cut" @click="switchTask">
                <i class="el-icon-sort"></i>切换窗口
              </span>
            </div>
            <teleport
              v-if="fileId != 0 || fileId != ''"
              :fileId="fileId"
              :to="courser"
            />
            <chat
              v-if="groupId && isChat"
              :fileId="fileId"
              :groupId="groupId"
            />
          </div>
          <div
            v-if="
              (!isSignIn && signInState) ||
              (isSignIn && signInState) ||
              (!isSignOut && signOutState) ||
              (isSignOut && signOutState) ||
              examId ||
              surveyId
            "
            class="wxq_operation"
          >
            <span v-if="!isSignIn && signInState" @click="getSignIn">
              <img
                alt=""
                src="@/assets/img/studentImg/liveDetail/sign-in-icon.png"
              />
              签到
            </span>
            <span v-if="isSignIn && signInState">
              <img
                alt=""
                src="@/assets/img/studentImg/liveDetail/signed-in-icon.png"
              />
              已签到
            </span>
            <span v-if="!isSignOut && signOutState" @click="getSignOut">
              <img
                alt=""
                src="@/assets/img/studentImg/liveDetail/sign-out-icon.png"
              />
              签退
            </span>
            <span v-if="isSignOut && signOutState">
              <img
                alt=""
                src="@/assets/img/studentImg/liveDetail/signed-out-icon.png"
              />
              已签退
            </span>
            <span v-if="examId" @click="toExam">
              <img
                alt=""
                src="@/assets/img/studentImg/liveDetail/exam-icon.png"
              />
              考试
            </span>
            <span v-if="surveyId" @click="editContent()">
              <img
                alt=""
                src="@/assets/img/studentImg/liveDetail/questionnaire-icon.png"
              />
              问卷
            </span>
          </div>
        </div>
        <div class="wxq_live_bottom">
          <div
            v-if="menuList.length"
            :style="{ width: lecturerDataList.length ? '904px' : '1200px' }"
            class="wxq_live_menu"
          >
            <el-tabs
              v-model="activeName"
              :class="{ wxq_tab: menuList.length == 1 }"
            >
              <el-tab-pane
                v-for="item in menuList"
                :key="item.menuId"
                :label="item.menuName"
                :name="item.menuName"
              >
                <live-menu-detail
                  :evaluateList="evaluationList"
                  :isEvaluate="isEvaluate"
                  :menuDetail="item"
                  :type="'live'"
                  @addSuccess="addSuccess"
                ></live-menu-detail>
              </el-tab-pane>
            </el-tabs>
          </div>
          <div class="wxq_lecturer" v-if="lecturerDataList.length">
            <teacher-com
              v-if="lecturerDataList.length"
              :teacherData="lecturerDataList"
            />
          </div>
        </div>
      </div>
      <el-drawer
        :title="survey.title"
        :visible.sync="drawerDisplay"
        size="480px"
      >
        <p class="wxq_remark">{{ survey.remark }}</p>
        <div class="drawer-contaier">
          <div class="question-list">
            <div v-for="item in questionsList" :key="item.questionId">
              <div class="head">
                <div :class="['field-name', item.required ? 'required' : '']">
                  <div>
                    {{ item.content }}（类型：{{
                      QUESTONAIRE_QUESTION_TYPE.get(item.questionType)
                    }}）
                  </div>
                </div>
              </div>
              <el-input
                v-if="[4].includes(item.questionType)"
                v-model="item.selecterValue"
                :placeholder="item.selecters[0].content"
                type="textarea"
                @blur="getSelectSeq($event, item.selecters[0], item)"
              />
              <el-radio-group
                v-if="[1].includes(item.questionType)"
                v-model="item.selecterSeqs"
              >
                <el-radio
                  v-for="radio in item.selecters"
                  :key="radio.selecterId"
                  :label="radio.selecterSeq"
                  class="block"
                  @change="getSelectSeq($event, radio, item)"
                >
                  {{ `${radio.content}` }}
                </el-radio>
              </el-radio-group>
              <el-checkbox-group
                v-if="item.questionType == 2"
                v-model="item.selecterSeqs"
              >
                <el-checkbox
                  v-for="i in item.selecters"
                  :key="i.selecterId"
                  :label="i.selecterSeq"
                  @change="getSelectSeq($event, i, item)"
                >
                  {{ item.content }}
                </el-checkbox>
              </el-checkbox-group>
            </div>
          </div>
          <div class="wxq_submit">
            <el-button class="btn btn_blue" @click="submit">提交</el-button>
          </div>
        </div>
      </el-drawer>
      <el-dialog
        v-if="isShareCode"
        :modal-append-to-body="false"
        :visible.sync="isShareCode"
        center
        class="dialog_wrapper"
        title="分享二维码"
        top="10vh"
        width="300px"
      >
        <qr-code :code="code" :courseId="courseId" />
      </el-dialog>
    </div>
  </div>
</template>

<script>
import chat from "./components/chat";
import liveMenuDetail from "./components/liveMenuDetail";
import liveVideo from "./components/liveVideo";
import qrCode from "@/components/studentsComponent/qrCode";
import teleport from "@views/studentsEnd/live/components/teleport";
import teacherCom from "@/components/studentsComponent/teacherCom";
// import API from "@/apis/backStageEndAPI/myCourseAPI";
import liveDetailAPI from "@/apis/studentsEndAPI/liveMenuDetail";
import CommonUtils from "@/utils";
import { createExam } from "@/apis/studentsEndAPI/exam";
import {
  checkSubmit,
  fetchExamDetail,
  fetchQuestionaireListById,
  submitQuestionStatistic,
} from "@/apis/backStageEndAPI/examStageManagement";
import {
  QUESTIONAIRE_STATUS,
  QUESTIONAIRE_TYPE,
  QUESTONAIRE_QUESTION_TYPE,
} from "@/utils/enum";
import { EventBus } from "@/assets/js/eventBus";
import lecturerAPI from "@apis/backStageEndAPI/lecturerAPI";
import LikeHeart from "@/plugins/likeHeart";
import moment from "moment";
export default {
  name: "liveDetail",
  components: {
    liveVideo,
    chat,
    liveMenuDetail,
    teacherCom,
    teleport,
    qrCode,
  },
  data() {
    return {
      QUESTONAIRE_QUESTION_TYPE,
      QUESTIONAIRE_STATUS,
      QUESTIONAIRE_TYPE,
      courseId: "", //课程ID
      courseTitle: "", //课程标题
      fileId: "", //课件ID
      video: "#wxq_live_left",
      courser: "#wxq_live_right",
      active: false, //收藏
      audience: 0, //观众数
      activeName: "first",
      isChat: true,
      isSignIn: 0, //签到 1: 已签到 0: 未签到
      isSignOut: 0, //签退 1: 已签退 0: 未签退
      isCollect: 0, //收藏 1: 已收藏 0: 未收藏
      signInState: 0, // 0：签到关闭，1：签到开启
      signOutState: 0, // 0：签退关闭，1：签退开启
      examId: "", //考试id
      surveyId: "", //问卷ID
      examData: {}, //考试详情
      isEvaluate: "", //是否评价: 1|已评价，0|未评价
      menuList: [], //菜单列表
      coverUrl: "", // 封面地址
      liveInfo: {}, // 直播详情
      evaluationList: [], //评价列表数据
      evaluationForm: {
        //获取评价列表的参数
        itemId: "", //课程id
        type: 1, //评价类型：1：课程；2：讲师；3：文章
        page: 1, //页码
        pageSize: 10, //分页大小
      },
      signInOutForm: {
        // 签到签退参数
        device: "PC", // 签到设备（PC,Android,iOS,H5）
        itemId: "", // 课程ID
        itemType: "1", // 签到类型：1：课程；3：考试
        signType: "1", // 签到类型：1：签到；2：签退
      },
      operationForm: {
        //收藏参数
        itemId: "", // 课程ID
        itemType: "1", // 收藏类型：1：课程；2：讲师
        collectType: "1", // 收藏类型：1(默认)：收藏；2：点赞
      },
      studyLogForm: {
        courseId: "",
        device: "PC",
        lastSec: 120,
      },
      userId: "", //用户ID
      code: "",
      groupId: "",
      isShareCode: false,
      interval: "",
      timer: null,
      drawerDisplay: false,
      questionsList: [],
      answerForm: {
        userId: this.userId,
        answerVOS: [],
      },
      survey: {},
      params: {
        from: null,
        path: "",
        token: "",
      },
      preview: "",
      lecturerForm: {
        //获取讲师参数
        page: 1,
        pageSize: 10,
        courseId: "",
        state: 1,
      },
      lecturerDataList: [],
      width: 200, //初始宽度150
      height: 250, //初始高度190
      heartList: [], //初始数组
      heartCount: 0, //累加计数初始值
      liveZanBaseNum: 0, //直播默认点赞人数
      liveOnlineBaseNum: 0, //直播默认观众人数
      realViewNum: 0, //直播观众真实人数
      ymd: "", //随机数
    };
  },
  watch: {
    liveInfo: {
      handler(val) {
        if (val.liveZanState == 1) {
          var _this = this;
          var ctx = document.getElementById("cvs").getContext("2d");
          (ctx.canvas.width = _this.width),
            (ctx.canvas.height = _this.height),
            (function loop() {
              ctx.clearRect(0, 0, _this.width, _this.height);
              _this.heartList.forEach(function (item) {
                item && item.move(ctx);
              });
              requestAnimationFrame(loop);
            })();
          setInterval(function () {
            _this.heartList.push(_this.createHeart());
          }, 700);
        }
      },
    },
  },
  mounted() {
    this.preview = this.$route.query.type;
    this.params = this.$store.state.fusion;
    EventBus.$emit("curIndex", 1);
    this.code = this.$route.query.code;
    if (!sessionStorage.getItem("educationToken")) {
      this.ymd = new Date().getTime() + Math.floor(Math.random() * 10000);
    }
    if (this.$route.query.id) {
      this.courseId = this.$route.query.id;
      this.signInOutForm.itemId = this.courseId;
      this.operationForm.itemId = this.courseId;
      this.evaluationForm.itemId = this.courseId;
      this.studyLogForm.courseId = this.courseId;
      this.lecturerForm.courseId = this.courseId;
      this.getDetail(this.courseId, this.code);
      this.getEvaluationList(this.courseId);
      this.getLecturerList(this.lecturerForm);
      if (!this.preview) {
        this.getStudyLog();
      }
      if (this.liveInfo.liveZanState == 1) {
        this.timer = setInterval(() => {
          this.saveCourseZan(this.courseId, this.liveZanBaseNum);
        }, 10000);
      }
    }
    this.userId =
      JSON.parse(sessionStorage.getItem("profile"))?.userId ||
      JSON.parse(localStorage.getItem("profile"))?.userId;
    if (!this.preview) {
      this.interval = setInterval(() => {
        this.getStudyLog();
      }, 10000);
    }
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    getDetail(id, code) {
      //获取详情
      liveDetailAPI.course(id, code).then((res) => {
        if (res && !res.status) {
          this.courseTitle = res?.courseTitle;
          this.fileId = res?.fileId;
          this.coverUrl = res?.coverUrl;
          this.liveInfo = res?.liveInfo;
          res.menuList.forEach((item, index) => {
            if (item.menuType != 1) {
              this.menuList.push(item);
              this.activeName = this.menuList[0].menuName;
            }
          });
          this.isChat = res.menuList.some((item) => {
            return item.menuType == 1;
          });
          this.isSignIn = res?.isSignIn;
          this.isSignOut = res?.isSignOut;
          this.isCollect = res?.isCollect;
          this.isEvaluate = res?.isEvaluate;
          if (res?.examId) {
            this.examId = res?.examId;
            this.getExamDetail(res?.examId);
          }
          if (res?.surveyId) {
            this.surveyId = res?.surveyId;
            // this.getExamDetail(res?.examId)
          }
          this.signInState = res?.signInState;
          this.signOutState = res?.signOutState;
          this.groupId = res?.groupId;
          this.liveZanBaseNum = res?.liveInfo.liveZanBaseNum;
          this.liveOnlineBaseNum =
            res?.realViewNum * res?.liveInfo.liveOnlineBaseTimes +
            res?.liveInfo.liveOnlineBaseNum;
          this.realViewNum = res?.realViewNum;
          if (this.liveInfo.liveZanState == 1) {
            this.getCourseZan(this.courseId);
          }
        } else {
          // this.$message.warning("请登录后再查看课程！");
          this.$router.go(-1);
        }
      });
    },
    getLecturerList(val) {
      //获取讲师
      if (sessionStorage.getItem("educationToken")) {
        lecturerAPI
          .getRelevancyTeacherList(CommonUtils.parseToParams(val))
          .then((res) => {
            if (res && !res.status) {
              this.lecturerDataList = res;
            }
          });
      }
    },
    editContent() {
      if (sessionStorage.getItem("educationToken")) {
        checkSubmit(this.surveyId).then((res) => {
          if (res && !res.status) {
            this.getQuestionList();
          } else {
            this.$message.warning("您已提交过问卷,感谢参与");
          }
        });
      } else {
        this.$message.warning("请先登录！");
        this.$store.state.isLogin = true;
      }
    },
    async getQuestionList() {
      this.drawerDisplay = true;
      const { questions = [], survey = {} } = await fetchQuestionaireListById({
        surveyId: this.surveyId,
      });
      this.survey = survey;
      questions.forEach((item) => {
        this.$set(item, "selecterValue", "");
        this.$set(item, "selecterSeqs", []);
        item.selecters.forEach((i) => {
          this.$set(i, "selecterValue", "");
          this.$set(i, "selecterSeqs", []);
          this.$set(i, "required", []);
        });
      });
      this.questionsList = questions;
    },
    submit() {
      if (this.answerForm.answerVOS.length == 0) {
        this.$message.warning("请回答完所有必填项再提交");
      } else {
        let some = this.questionsList.some((item) => {
          if (item.required) {
            return item.selecterSeqs.length == 0 && item.selecterValue == "";
          }
        });
        some
          ? this.$message.warning("请回答完所有必填项再提交")
          : submitQuestionStatistic({
              userId: this.userId,
              answerVOS: this.answerForm.answerVOS,
            }).then((res) => {
              if (res.code == 0) {
                this.drawerDisplay = false;
                this.$message.success("提交成功，感谢参与");
              }
            });
      }
    },
    getSelectSeq(val, item, res) {
      if (res.questionType == 1) {
        item.selecterSeqs[0] = res.selecterSeqs;
      } else if (res.questionType == 2) {
        item.selecterSeqs = res.selecterSeqs;
      } else if (res.questionType == 4) {
        item.selecterValue = res.selecterValue;
      }
      item.questionId = res.questionId;
      item.surveyId = this.survey.surveyId;
      item.required = res.required;
      this.answerForm.answerVOS.push(item);
      let newArr = this.answerForm.answerVOS;
      //数组去重选择最后一条数据
      for (var i = 0; i < newArr.length; i++) {
        for (var j = i + 1; j < newArr.length; j++) {
          if (newArr[i].questionSeq == newArr[j].questionSeq) {
            newArr.splice(i, 1);
            j--;
          }
        }
      }
      this.answerForm.answerVOS = newArr;
      console.log(this.answerForm.answerVOS);
    },
    getEvaluationList(id) {
      //获取评价列表
      this.evaluationForm.itemId = id;
      liveDetailAPI
        .getEvalution(CommonUtils.parseToParams(this.evaluationForm))
        .then((res) => {
          if (res && !res.status) {
            this.evaluationList = res.dataList;
            this.totalCount = res.rowCount;
          }
        });
    },
    async toExam() {
      //考试
      if (sessionStorage.getItem("educationToken")) {
        const { times, tryCount = 0 } = this.examData;
        if (times) {
          if (times - tryCount < 0 || !(times - tryCount)) {
            return this.$message.warning("您没有剩余的考试次数了");
          }
        }
        const { data } = await createExam({
          examId: this.examData.id,
          password: "",
        });
        this.$router.push(`/answer?id=${data.id}`);
      } else {
        this.$message.warning("请先登录！");
        this.$store.state.isLogin = true;
      }
    },
    getExamDetail(examId) {
      if (sessionStorage.getItem("educationToken")) {
        fetchExamDetail({ id: examId }).then((res) => {
          this.examData = res.data;
        });
      }
    },
    addSuccess() {
      //评论添加成功回调
      this.getEvaluationList(this.courseId);
    },
    switchTask() {
      //切换窗口
      this.video =
        this.video == "#wxq_live_left" ? "#wxq_live_right" : "#wxq_live_left";
      this.courser =
        this.courser == "#wxq_live_right"
          ? "#wxq_live_left"
          : "#wxq_live_right";
    },
    getSignIn() {
      // 签到
      this.signInOutForm.signType = "1";
      if (
        sessionStorage.getItem("educationToken") ||
        localStorage.getItem("educationToken")
      ) {
        liveDetailAPI.signRecord(this.signInOutForm).then((res) => {
          if (res && !res.status) {
            this.isSignIn = 1;
          }
        });
      } else {
        this.$message.warning("请先登录！");
        this.$store.state.isLogin = true;
      }
    },
    getSignOut() {
      // 签退
      this.signInOutForm.signType = "2";
      if (
        sessionStorage.getItem("educationToken") ||
        localStorage.getItem("educationToken")
      ) {
        liveDetailAPI.signRecord(this.signInOutForm).then((res) => {
          if (res && !res.status) {
            this.isSignOut = 1;
          }
        });
      } else {
        this.$message.warning("请先登录！");
        this.$store.state.isLogin = true;
      }
    },
    getOperation() {
      if (this.isCollect) {
        this.delCollect();
      } else {
        this.addCollect();
      }
    },
    addCollect() {
      // 收藏
      if (
        sessionStorage.getItem("educationToken") ||
        localStorage.getItem("educationToken")
      ) {
        liveDetailAPI
          .addCollect(this.userId, this.operationForm)
          .then((res) => {
            if (res && !res.status) {
              this.isCollect = 1;
            }
          });
      } else {
        this.$message.warning("请先登录！");
        this.$store.state.isLogin = true;
      }
    },
    delCollect() {
      // 取消收藏
      liveDetailAPI.delCollect(this.userId, this.operationForm).then((res) => {
        if (res && !res.status) {
          this.isCollect = 0;
        }
      });
    },
    share() {
      this.isShareCode = !this.isShareCode;
    },
    getStudyLog() {
      if (
        sessionStorage.getItem("educationToken") ||
        localStorage.getItem("educationToken")
      ) {
        liveDetailAPI
          .getStudyLog("/study-log", this.studyLogForm, { isShow: false })
          .then((res) => {});
      } else {
        let params = {
          lastChapterId: "1-1",
          userId: this.ymd,
        };
        let studyLog = Object.assign(this.studyLogForm, params);
        liveDetailAPI
          .visitorStudyLog("/visitor-study-log", studyLog, { isShow: false })
          .then((res) => {});
      }
    },
    good() {
      let _this = this;
      _this.heartList.push(_this.createHeart());
      this.liveZanBaseNum++;
    },
    async getCourseZan(courseId) {
      let data = await liveDetailAPI.getCourseZan(courseId);
      if (data && !data.status) {
        this.liveZanBaseNum = data.liveZanNum;
      }
    },
    async saveCourseZan(courseId, liveZanNum) {
      let params = {
        courseId,
        liveZanNum,
      };
      let data = await liveDetailAPI.saveCourseZan(params);
      if (data && !data.status) {
      }
    },
    getRandomDis() {
      if (Math.random() > 0.5) {
        return -(Math.random() * 43);
      } else {
        return +(Math.random() * 43);
      }
    },
    createHeart() {
      this.heartCount++;
      let positionArray = [
        {
          x: 90, //100
          y: 330, //330
          endX: 100, //100
          endY: 100, //100
        },
      ];
      let img = new Image();
      img.src = require("@/assets/img/studentImg/liveDetail/" +
        Math.ceil(Math.random() * 25) +
        ".png");
      let p1 = {
        x: 100 + this.getRandomDis(),
        y: 300 + this.getRandomDis(),
      };
      let p2 = {
        x: 100 + this.getRandomDis(),
        y: 200 + this.getRandomDis(),
      };
      return new LikeHeart({
        id: this.heartCount,
        x: positionArray[0].x,
        y: positionArray[0].y,
        endX: positionArray[0].endX,
        endY: positionArray[0].endY,
        onFadeOut: this.removeItem,
        noAngel: true, //决定是否从小到大
        //noScale: false,//决定是否左右摆动
        width: 20, //决定心的大小
        height: 20,
        image: img,
        bezierPoint: {
          p0: {
            x: positionArray[0].x,
            y: positionArray[0].y,
          },
          p1: p1,
          p2: p2,
          p3: {
            x: positionArray[0].endX,
            y: positionArray[0].endY,
          },
        },
      });
    },
    removeItem(item) {
      var array = [];
      for (var i = 0; i < this.heartList.length; i++) {
        if (this.heartList[i].id !== item.id) {
          array.push(this.heartList[i]);
        }
      }
      this.heartList = array;
    },
  },
  destroyed() {
    if (this.liveInfo.liveZanState == 1) {
      this.saveCourseZan(this.courseId, this.liveZanBaseNum);
      clearInterval(this.timer);
    }
    if (!this.preview) {
      this.getStudyLog();
      clearInterval(this.interval);
    }
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .vcp-panel-bg {
    z-index: 1000 !important;
  }
}
.container {
  .el-breadcrumb__item {
    padding-top: 16px;
    padding-left: 16px;
    font-size: 14px;
    ::v-deep .is-link {
      color: var(--colorBlue);
      cursor: pointer;
      .el-icon-back {
        margin-right: 6px;
      }
    }
  }
}
.wxq_container {
  padding: 24px 0;
  background: #f7faff;
  .wxq_shade {
    width: 100%;
    height: calc(100vh - 94px);
    position: absolute;
    z-index: 1000;
  }
  .wxq_live {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
    height: 572px;
    .wxq_live_left {
      position: relative;
      display: flex;
      flex: 1;
      flex-direction: column;
      padding: 12px;
      width: 896px;
      height: 100%;
      border-radius: 6px;
      background: #fff;
      box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1);
      #wxq_live_left {
        height: calc(100% - 90px);
      }
      .wxq_live_title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 18px;
        height: 90px;
        p {
          overflow: hidden;
          width: 60%;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-weight: 500;
          font-size: 24px;
        }
        .wxq_live_operation {
          height: 87px;
          line-height: 87px;
          position: relative;
          display: flex;
          justify-content: space-between;
          .eyes {
            width: 100px;
            position: absolute;
            top: 0;
            right: 150px;
            vertical-align: middle;
            text-align: center;
            img {
              width: 26px;
              height: 21px;
              margin-right: 5px;
              vertical-align: middle;
            }
          }
          span {
            // margin-right: -5px;
            vertical-align: middle;
            color: #666;
            font-weight: 500;
            font-size: 16px;
            cursor: pointer;
            i {
              margin-right: 6px;
            }
            img {
              // margin-right: 6px;
              width: 16px;
            }
            .thumbs {
              width: 86px;
              height: 86px;
              vertical-align: middle;
              position: relative;
            }
          }
          .thumbs_absolute {
            position: absolute;
            right: 12px;
            bottom: -10px;
            width: 58px;
            height: 19px;
            text-align: center;
            line-height: 19px;
            font-size: 14px;
            color: #fff;
            background: linear-gradient(180deg, #ffbcbc 0%, #ff8c8c 100%);
            box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.11);
            border-radius: 10px;
            border: 2px solid #ffffff;
          }

          .el-icon-star-on {
            color: var(--colorOrange);
          }
        }
      }
      .yh_canvas {
        width: 150px;
        height: 250px;
        position: absolute;
        right: 10px;
        bottom: 88px;
      }
    }
    .wxq_live_right {
      display: flex;
      flex-direction: column;
      margin-left: 16px;
      width: 280px;
      height: 100%;
      #wxq_live_right {
        position: relative;
        margin-bottom: 8px;
        height: 20%;
        .wxq_cut {
          position: absolute;
          top: 134px;
          z-index: 900;
          display: -ms-flexbox;
          display: flex;
          -ms-flex-pack: justify;
          justify-content: space-between;
          padding: 4px;
          height: 16px;
          border-radius: 0 4px 0 0;
          background-color: #fff;
          color: #458aff;
          font-size: 12px;
          line-height: 17px;
          cursor: pointer;
          .el-icon-sort {
            margin-right: 4px;
            transform: rotate(90deg);
          }
        }
      }
    }
    .wxq_operation {
      position: fixed;
      top: 50%;
      right: 40px;
      z-index: 999;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      padding: 18px 0;
      width: 65px;
      border: 1px solid #d4e5ff;
      border-radius: 33px;
      background: #fff;
      transform: translateY(-20%);
      span {
        display: flex;
        align-items: center;
        flex-direction: column;
        margin-bottom: 12px;
        cursor: pointer;
      }
      span:last-of-type {
        margin-bottom: 0;
      }
      img {
        margin-bottom: 6px;
        width: 35px;
      }
    }
  }
  .wxq_live_bottom {
    display: flex;
    justify-content: space-between;
    .wxq_live_menu {
      background: #fff;
      flex: 1;
      ::v-deep .el-tabs__nav-wrap {
        .el-tabs__item {
          padding: 0 30px;
        }
      }
      ::v-deep .el-tabs__content {
        padding: 0 30px 15px;
      }
      .wxq_tab {
        ::v-deep .el-tabs__active-bar {
          transform: none !important;
        }
      }
    }
    .wxq_lecturer {
      margin-left: 16px;
      width: 280px;
      ::v-deep .wxq_teacher {
        margin: 0 0 15px 0;
      }
    }
  }
  .head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 63px;
    .yfield {
      margin-bottom: 0;
    }
    .text {
      display: flex;
      align-items: center;
      .border {
        border: 1px solid #f0f0f0;
        border-radius: 50%;
      }
    }
    span {
      color: #262626;
      font-size: 14px;
    }
    svg {
      display: inline-block;
      margin-right: 5px;
      font-size: 24px;
    }
    > div {
      display: flex;
      align-items: center;
    }
  }
  ::v-deep {
    .el-drawer {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    .wxq_remark {
      padding: 20px 20px 0 20px;
      font-size: 16px;
    }
    .drawer-contaier {
      overflow: auto;
      flex: 1;
      padding: 24px;
      .wxq_submit {
        margin-top: 20px;
        text-align: center;
      }
    }
    .el-drawer__header {
      flex: 0 0 60px;
      margin: 0;
      padding: 0 24px;
      outline: none;
      border: none;
      border-bottom: 1px solid #efefef;
      line-height: 60px;
    }
    .el-drawer__body {
      display: flex;
      overflow: hidden;
      flex: 1;
      flex-direction: column;
      justify-content: space-between;
    }
  }
  .field-name {
    &.required {
      &::before {
        margin-right: 4px;
        color: #f56c6c;
        content: "*";
      }
    }
  }
}
</style>
